(function(wnd){
    function EpdentForm(container, params) {
        try {
            if (!container) {
                throw 'Container is required!';
            }

            this.container = container;

            if (params) {
                this.setParams(params);
            }

            this.init();
        } catch(e) {
            console.error(e);
        }
    }

    EpdentForm.prototype = {
        container: undefined,
        form:undefined,
        button: undefined,
        successCallback: undefined,
        notyf:undefined,
        sendedForm: false,
        crmForm: undefined,
        formContent: undefined,
        confirmContent: undefined,
        conversionId: undefined,
        init: function() {
            this.setElements();
            this.addDOMEvents();
        },
        setElements: function() {
            this.button = this.container.find('[data-purpose="send-epdent-form"]');
            this.notyf = new Notyf;
            this.form = this.container.find('form');
            this.crmForm = $('#miniCRMForm');
            this.formContent = this.container.find('[data-purpose="form-content"]');
            this.confirmContent = this.container.find('[data-purpose="confirm-content"]');
        },
        addDOMEvents: function() {
            var _self = this;
            
            if (this.button && _self.form && _self.form.length == 1) {
                this.button.click(function() {
                    _self.sendForm();
                });
            } 

            this.container.find('.phone-field').keypress(function(e){
                var code = typeof e.which !== 'undefined' && e.which ? e.which : e.keyCode;
                var exeptions = [37, 39];
                var key = typeof e.key !== 'undefined' ? e.key : undefined;

                if (key && (key == "'" || key == "%")) {
                    return false;
                }

                if (exeptions.indexOf(code) >= 0) {
                    return true;
                }
                
                if (code > 31 && (code < 48 || code > 57)) {
                    return false;
                }
        
                return true;
            });

            this.container.find('[data-purpose="back-to-form"]').click(function(){
                _self.resetContent();
            });

            $(document).on('closed', '.contact-remodal', function(){
                _self.resetContent();
            });
        },
        setParams: function(params) {
            if (params.conversionId) this.setConversionId(params.conversionId)
        },
        setConversionId: function(conversionId) {
            this.conversionId = conversionId;
        },
        sendForm: function() {
            var _self = this;
            if (!this.sendedForm) {
                _self.sendedForm = true;
                
                $.ajax({
                    url:_self.form.attr('action'),
                    dataType:'json',
                    type:'post',
                    data:_self.form.serialize(),
                    beforeSend: function() {
                        _self.container.find('.has-error').removeClass('has-error');
                    },
                    success: function(response) {
                        if (typeof gtag === 'function') {
                            var nameInput = _self.form.find('input[name="name"]');
                            var name = nameInput.length > 0 && (nameInput.val()).trim() !== '' ? nameInput.val() : 'Anonymous'    

                            gtag('event', 'Ajánlatkérés', {
                                'event_category': 'Lead',
                                'event_action': (response.success ? 'Sikeres' : 'Sikertelen')+' ajánlatkérés',
                                'event_label': name
                            });

                            if (_self.conversionId && typeof gtag_report_conversion === 'function') {
                                gtag_report_conversion(_self.conversionId);
                            }
                        }
                        if (response.success) {
                            // _self.showConfirmContent();
                            _self.sendToCRM(response);   
                        } else {
                            if (typeof response.errors == 'object') {
                                $.each(response.errors, function(key, value) {
                                    if (value.trim() !== '') {
                                        _self.notyf.alert(value);
                                    }

                                    var input = _self.container.find('[name="'+key+'"]');
                                    if (input.length > 0) {
                                        input.addClass('has-error');
                                    }
                                });
                            }
                        }
                    },
                    complete: function(){
                        setTimeout(function(){
                            _self.sendedForm = false;
                        }, 1000);
                    }
                });
            }
        },
        sendToCRM: function(response){
            var wait = 50;
            if (this.crmForm && this.crmForm.length > 0) {
                var _self = this;
                wait = 1000;

                this.form.find('input[type="text"], textarea').each(function(key, item){
                    var field = $(item);
                    var target = _self.crmForm.find('[data-form-name="'+field.attr('name')+'"]');
                    
                    if (target.length > 0) {
                        target.val(field.val());

                        field.val('');
                    }
                });

                this.form.find('input[type="checkbox"]').prop('checked', false);

                this.crmForm.submit();

                
            } else {
                this.form.find('input[type="text"]').val('');
                this.form.find('textarea').val('');
                this.form.find('input[type="checkbox"]').prop('checked', false);
            }

            if (typeof response.url !== 'undefined' && response.url) {
                setTimeout(function() {
                    document.location.href = response.url;
                }, wait);
            } else {
                this.showConfirmContent();
            }
        },
        showConfirmContent: function() {
            this.container.addClass('success-sent');
        }, 
        resetContent: function() {
            this.container.removeClass('success-sent');
        }
    }


    wnd.EpdentForm = EpdentForm;
})(window);
