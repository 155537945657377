(function(wnd){
    function ContactMapSection(container, params) {
        try {
            if (!container || (container && container.length == 0)) {
                throw 'Container is required!';
            }

            this.container = container;
            if (params) {
                this.setParams(params);
            }

            this.init();
        } catch (error) {
            console.error(error);
        }
    }

    ContactMapSection.prototype = {
        container: undefined,
        labels: undefined,
        contents: undefined,
        selectors: {
            'label': '[data-purpose="tab-label"]',
            'content': '[data-purpose="tab-content"]'
        },
        coordinates: {
            london: {
                lng: -0.14195,
                lat: 51.54599,
            },
            debrecen: {
                lng: 21.607824199999982,
                lat: 47.52116050000001,
            },
            munchen: {
                lng: 11.50324,
                lat: 48.13369,
            }
        },
        cityImages: {},
        currentCity: undefined,
        map: undefined,
        mapImage: undefined,
        init: function() {
            this.setElements();
            this.addDOMEvents();
            this.initMap();
        },
        setElements: function() {
            this.labels = this.container.find(this.getSelector('label'));
            this.contents = this.container.find(this.getSelector('content'));
            this.mapImage = this.container.find('[data-purpose="map-image"]');
        },
        addDOMEvents: function() {
            var _self = this;
            
            if (this.labels && this.labels.length > 0 && this.contents && this.contents.length > 0) {
                this.labels.click(function(){
                    if (!$(this).hasClass('active')) {
                        _self.labels.removeClass('active');
                        $(this).addClass('active');

                        var city = $(this).data('city');
                        _self.currentCity = city;

                        _self.changeCity();
                    }
                }).first().click();
            }
        },
        setParams: function(params) {
            if (params.currentCity) { this.currentCity = params.currentCity; }
            if (params.cityImages) { this.cityImages = params.cityImages; }
        },
        getSelector: function(key) {
            if (typeof this.selectors[key] === 'undefined') {
                throw key+' selector is undefined!';
            }

            return this.selectors[key];
        },
        initMap: function() {
            if ($('#contactMap').length === 0) {
                return;
            }
            
            if (typeof mapboxgl !== 'undefined' && mapboxgl.supported()) {
                var _self = this;

                var coordinates = typeof this.coordinates[this.currentCity] !== 'undefined' ? this.coordinates[this.currentCity] : undefined;
                var center = coordinates ? [coordinates.lng, coordinates.lat] : [-0.14195,  51.54599]
                mapboxgl.accessToken = 'pk.eyJ1IjoiZXBkZW50IiwiYSI6ImNqemIxYTM0NDAzMXkzZGsyZXYyM2JnaGUifQ.yTqTmSrPHHEeXlNJpHzA2A';
                this.map = new mapboxgl.Map({
                    container:'contactMap',
                    style:'mapbox://styles/epdent/ck6lwcjmz0lst1im36l81jtcb',
                    zoom:18,
                    center: center
                });

                this.map.scrollZoom.disable();

                $.each(this.coordinates, function(key, values) {
                    var el = document.createElement('div');
                    el.className = 'map-marker '+key+'-marker'

                    new mapboxgl.Marker(el).setLngLat([values.lng, values.lat]).addTo(_self.map);
                });
            } 
        },
        changeCity: function() {
            city = this.currentCity;
            this.contents.removeClass('active');

            this.contents.each(function(){
                if ($(this).data('city') == city) {
                    $(this).addClass('active');
                }
            });

            if (this.mapImage.length > 0) {
                if (typeof this.cityImages[city] !== 'undefined') {
                    this.mapImage.show();
                    var images = this.cityImages[city];
                    var src = window.devicePixelRatio > 1.4 ? images.retina : images.normal;
                    this.mapImage.attr('src', src);
                } else {
                    this.mapImage.hide();
                }
            }

            if (this.map) {
                var coordinates = typeof this.coordinates[city] !== 'undefined' ? this.coordinates[city] : undefined;
                if (coordinates) {
                    this.map.jumpTo({
                        center: [coordinates.lng, coordinates.lat]
                    });
                }
            }
        }
    };

    wnd.ContactMapSection = ContactMapSection;
})(window);
